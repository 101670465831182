@use '@angular/material' as mat;

$header-inner-height: 4.6rem;
$header-outer-height: 6.2rem;

$controls-sidebar-width: 7rem;

$small-padding: 0.4rem;
$medium-padding: 0.8rem;
$large-padding: 1.2rem;

$small-spacing: 0.4rem;
$medium-spacing: 0.8rem;
$large-spacing: 1.2rem;
$extra-large-spacing: 2.4rem;
