@use '@angular/material' as mat;
@import './globals';

.mat-button-toggle-group.mat-button-toggle-group-appearance-standard{
	.mat-button-toggle {
		color: white;
		background-color: mat.get-color-from-palette($primary);
	}

	.mat-button-toggle-checked{
		background-color: white;
		color: mat.get-color-from-palette($primary);
		border-radius: 20%;
	}

	.mat-icon{
		font-size: 1.714285714em;
		width: 1em;
		height: 1em;
	}

	.mat-button-toggle-label-content{
		padding: 0 0.6em;
		line-height: 2.571428571em;
	}

	&.icon{
		.mat-button-toggle-label-content{
			padding: 0 0.285714286em;
			line-height: 1em;
		}
	}

	.mat-button-toggle + .mat-button-toggle{
		border-left-style: none;
	}

	&.compact{
		//supposed to be same size as compact inputs so they can go next to each other easily

		.mat-button-toggle-label-content{
			line-height: calc(0.5em + 1.125em + 0.5em);
		}
	}
}
