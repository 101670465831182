@use '@angular/material' as mat;
@import './globals';


.mat-mdc-button.mat-mdc-button-base{
	font-size: inherit;
	line-height: normal;
	min-width: 0;
	padding: var(--spacing-small);
	background: var(--panel-background);
	height: auto;

	.mat-mdc-button-touch-target{
		width: calc(100% + var(--spacing-small)*2);
		height: calc(100% + var(--spacing-small)*2);
		top: 50%;
		left: 50%;
		right: none;
		transform: translate(-50%, -50%);
	}

	mat-icon{
		margin-right: var(--spacing-small);
	}
}

.mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 1.166666em;
	--mdc-icon-button-icon-size: 1.166666em;
	font-size: inherit;
    padding: var(--spacing-small);
	box-sizing: content-box;
	background: var(--panel-background);
    // width: 1.166666em;
    // height: 1.166666em;
    // height: auto;
    line-height: 1;

	>.mat-icon, >svg{
		display: block;
	}

	.mat-mdc-button-touch-target{
		width: calc(100% + var(--spacing-small)*2);
		height: calc(100% + var(--spacing-small)*2);
	}

	&.inline{
		margin: -0.35em;
	}

}

