.mat-icon-button{
	&.inline{
		//inline icon buttons should flow with the text and be the same size as the font
		font-size: inherit;
		height: 1em;
		width: 1em;
		padding: $small-padding;
		margin: -#{$small-padding};
		box-sizing: content-box;
		line-height: normal;
		display: inline-block;
		vertical-align: baseline;

		.mat-button-wrapper{
			width: 1em;
			height: 1em;
			line-height: 1em;
			display: block;
		}

		mat-icon.mat-icon{
			font-size: 1em;
			width: 1em;
			height: 1em;
			vertical-align: top;
			line-height: 1em;
		}
	}

	&.slim{
		width: 2.4rem;
		height: 2.4rem;
		line-height: 2.4rem;

		.mat-button-wrapper{
			display: block;
			padding: 0;

			.mat-icon{
				display: block;
			}
		}
	}

	&.compact{
		//supposed to be same size as compact inputs so they can go next to each other easily
		width: 2.175em;
		height: 2.175em;
		line-height: 2.175em;
	}

	&.no-padding{
		padding: calc(0);
		width: 2.4rem;
		height: 2.4rem;
		line-height: 2.4rem;
	}

	&.small-padding{
		padding: calc(4/14*1em);
	}

	&.medium-padding{
		padding: calc(8/14*1em);
	}

	&.large-padding{
		padding: calc(12/14*1em);
	}

}

//fake select component since mat-select doesn't support html inside the selected label
.mat-button.mat-button.select{
	text-align: left;
	border: solid 0.1rem mat.get-color-from-palette($foreground, divider);
	padding: calc(0.5em - 0.1rem);
	padding-right: calc(1.8em - 0.2rem);

	.select-arrow{
		width: 0;
		height: 0;
		border-left: 0.4em solid transparent;
		border-right: 0.4em solid transparent;
		border-top: 0.4em solid mat.get-color-from-palette($foreground, icon);
		position: absolute;
		right: calc(0.5em - 0.1rem);
		top: calc(50% - 0.2em);

	}

	&:hover{
		border-color: currentColor;
		transition: border-color 0ms cubic-bezier(0, 0, 0.2, 1);

		.select-arrow{
			border-top-color: currentColor;
			transition: border-color 0ms cubic-bezier(0, 0, 0.2, 1);
		}
	}
}

.mat-menu-content .mat-menu-item.selected{
	background-color: mat.get-color-from-palette($foreground, divider);
}

.mat-mini-fab.mat-mini-fab{
	box-shadow: none;
	color: mat.get-color-from-palette($background, background);
	font-size: 4rem;
	width: 1em;
	height: 1em;

	.mat-button-wrapper{
		display: block;
		padding: 0.2em;

		.mat-icon{
			display: block;
			font-size: 0.6em;
		}
	}

	&.inline{
		//inline icon buttons should flow with the text and be the same size as the font
		font-size: inherit;
		height: 1em;
		width: 1em;
		padding: $small-padding;
		margin: -#{$small-padding};
		box-sizing: content-box;
		line-height: normal;
		display: inline-block;
		vertical-align: baseline;

		.mat-button-wrapper{
			width: 1em;
			height: 1em;
			padding: 0;

			mat-icon.mat-icon{
				font-size: 1em;
			}
		}

	}

	&.secondary{
		background-color: mat.get-color-from-palette($primary,0.5);
	}

	&.primary{
		background-color: mat.get-color-from-palette($primary);
	}

	.mat-button-wrapper>*{
		vertical-align: top;
	}

	&.small-padding{
		font-size: 3.2rem;

		.mat-button-wrapper{
			padding: 0.125em;

			.mat-icon{
				font-size: 0.75em;

			}
		}
	}

	&.slim{
		font-size: 2.4rem;

		.mat-button-wrapper{
			padding: 0;

			.mat-icon{
				font-size: 1em;
			}
		}
	}

	&.compact{
		//supposed to be same size as compact inputs so they can go next to each other easily
		font-size: 2.175em;
	}
}
