@use '@angular/material' as mat;
@import './globals';

.mat-icon.mat-icon{
	position: relative;
	width: 1em;
	height: 1em;
	line-height: 1em;
	font-size: 2.4rem;

	&.inline{
		font-size: inherit;
		width: 1em;
		line-height: inherit;
		vertical-align: middle;

		&.big{
			width: 2em;
		}
	}

	&.big, &.large{
		font-size: 3.2rem;
	}

	&.huge{
		font-size: 4rem;
	}

	&.small{
		font-size: 1.6rem;
	}

	.file-ext{
		font-size: 0.25em;
		font-weight: bold;
		display: block;
		position: absolute;
		left: 10%;
		top: 43%;
		font-family: 'Lato';
		background-color: mat.get-color-from-palette($foreground, text);
		line-height: 1em;
		color: mat.get-color-from-palette($background, background);
		padding: 0.2em;
		border-radius: 0.2em;
	}

	.file-ext::before{
		content: '\25A0';
	}
}

.mat-icon.mat-icon.mat-icon-inline{
	width: 1em;
	line-height: inherit;
	vertical-align: bottom;
}

.mat-icon.mat-icon{
    display: inline-block;
	font-size: 1.166666em;
    line-height: 1em;
    overflow: hidden;
}
