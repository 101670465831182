
@use '@angular/material' as mat;
@import 'themes';
@include mat.core();

@import 'src/styles/globals';

$font-family: Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

html {
	height: 100%;
	width: 100%;
	font-size: 62.5%;
}

* {
	box-sizing: border-box;
}

body {
	height: 100%;
	width: 100%;
	background-color: map-get($background, background);
	color: map-get($foreground, text);
	font-size: 1.4rem;
	font-family: $font-family;
	margin: 0;
}

a {
	color: mat.get-color-from-palette($primary, default);
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

.parent-container .sidenav-container .mat-button-toggle-button {
    width: auto
}

.filters {
    height: 100%;
    display: flex;
    gap: 2rem;
	padding-left: 10px;

    .mat-mdc-form-field {
        height: 100%;
        width: 25%;
		margin-top: 6px;

		&.resize-form-field {
			width: 9%;

			@media screen and (max-width: 1440px) {
				width: 9%;
			}
		}

		:not(.mat-form-field-disabled) .mat-mdc-floating-label {
			top: 16px;
		}

        .mat-mdc-text-field-wrapper {
            height: 80%;
			border: none;
			.mdc-notched-outline {
                .mdc-notched-outline__notch {
					.mat-mdc-floating-label.mdc-floating-label  {
						top:16px;
						&.mdc-floating-label--float-above {
							top: 26px;
						}
					}
                }
                .mat-mdc-form-field-infix {
                    padding: 5px 0;
                }
			}
        }
    }

	.date-range-picker.mat-mdc-form-field {
		width: 25%;

		.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
			padding: 10px 0;
		}
	}
}

.highchart-wrapper {
    margin: 20px 0px;
}

.loading-logo {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

mat-tab-group.mat-mdc-tab-group, .mat-mdc-tab-body-wrapper {
	height: 100%;

	.mat-mdc-header-row {
		font-weight: bold;
	}

	.mdc-data-table__header-cell,
	.mdc-data-table__cell {
		padding: 5px;
	}

	.mat-mdc-tab-body {
		background-color: white;
	}
	
	.mat-mdc-paginator {
		width: 100%;
		display: flex !important;
		justify-content: center;
	}
	
}

.content-container {
    height: 100%;
    padding: 1rem;

    table {
        border: 1px solid var(--mat-table-row-item-outline-color);
        width: 100%;

        tr, th, td {
            height: 3rem;
        }

        input {
            padding: 5px;
            border: none;

            &:active, &:focus {
                background-color: white;
                border: 1px solid #7080FF;
            }
        }

        input[type=number] {
            width: 8rem;
        }

        .select-project {
            display: block;
            width: 15rem;
            padding: 0 0.5rem;
        }
    }
}

highcharts-chart {
    width: 98%;
    height: 98%;
    display: block;
}

@import 'src/styles/media-queries';
@import 'src/styles/reset';
@import 'src/styles/mat-button';
@import 'src/styles/mat-button-toggle';
@import 'src/styles/mat-icon-button';
@import 'src/styles/mat-icon';
@import 'src/styles/mat-calender';
@import 'src/styles/mat-form-field';
@import 'src/styles/mat-paginator';
@import 'src/styles/mat-select';
@import 'src/styles/mat-spinner';
@import 'src/styles/reset';
