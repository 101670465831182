@use '@angular/material' as mat;

$mat-strayos: (
	50: hsl(229, 100%, 92%),
	100: hsl(229, 100%, 88%),
	200: hsl(229, 100%, 84%),
	300: hsl(229, 100%, 80%),
	400: hsl(229, 100%, 76%),
	500: hsl(229, 100%, 66%),
	600: hsl(232.25, 100%, 59%),
	700: hsl(235.5,  100%, 52%),
	800: hsl(238.75, 100%, 45%),
	900: hsl(242,    100%, 38%),
	A100: #ff8a80,
	A200: #ff5252,
	A400: #ff1744,
	A700: #d50000,
	contrast: (
		50: rgba(0,0,0,0.87),
		100: rgba(0,0,0,0.87),
		200: rgba(0,0,0,0.87),
		300: rgba(0,0,0,0.87),
		400: rgba(0,0,0,0.87),
		500: white,
		600: white,
		700: white,
		800: rgba(255,255,255,0.87),
		900: rgba(255,255,255,0.87),
		A100: rgba(0,0,0,0.87),
		A200: white,
		A400: white,
		A700: white,
	)
);

$primary: mat.define-palette($mat-strayos);
$accent: mat.define-palette($mat-strayos);
$warn: mat.define-palette(mat.$red-palette);

$foreground: (
    base: black,
    divider: rgba(black, 0.12),
    dividers: rgba(black, 0.12),
    disabled: rgba(black, 0.38),
    disabled-button: rgba(rgb(5, 5, 5), 0.26),
    disabled-text: rgba(black, 0.38),
    elevation: black,
    hint-text: rgba(black, 0.38),
    secondary-text: rgba(black, 0.54),
    icon: rgba(black, 0.54),
    icons: rgba(black, 0.54),
    text: rgba(black, 0.8),
    slider-min: rgba(black, 0.8),
    slider-off: rgba(black, 0.26),
    slider-off-active: rgba(black, 0.38)
);

$background: (
    status-bar: map_get(mat.$grey-palette, 300),
    app-bar: map_get(mat.$grey-palette, 100),
    background: map_get(mat.$grey-palette, 50),
    scroll-background: map_get(mat.$grey-palette, 300),
    hover: rgba(black, 0.04),
    card: white,
    dialog: white,
    disabled-button: rgba(black, 0.12),
    raised-button: white,
    focused-button: rgba(black, 0.12),
    selected-button: map_get(mat.$grey-palette, 300),
    selected-disabled-button: map_get(mat.$grey-palette, 400),
    disabled-button-toggle: map_get(mat.$grey-palette, 200),
    unselected-chip: map_get(mat.$grey-palette, 300),
    disabled-list-option: map_get(mat.$grey-palette, 200),
    tooltip: map_get(mat.$grey-palette, 700),
    selected-background: map-get($mat-strayos, 300),
);

$theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  ),
  density: -1
));

@include mat.all-component-themes($theme);

.signin-signup-container {
  .left {
    &:before {
      background-image: url('/assets/images/strayos-drone-quarry.png');
    };
  }
}